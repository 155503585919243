import styled, { keyframes } from 'styled-components'
import { Balance } from '@pancakeswap/uikit'
// import { LotteryStatus } from 'config/constants/types'
// import { useTranslation } from '@pancakeswap/localization'
import { usePriceCakeUSD } from 'state/farms/hooks'
import { useLottery } from 'state/lottery/hooks'
import { getBalanceNumber } from '@pancakeswap/utils/formatBalance'
// import BuyTicketsButton from './BuyTicketsButton'
// import ticketImage from '../../Lottery/Assests/Images/Hashfair Ticket.png'
// import Svg from "../Svg";
// import { SvgProps } from "../types";

export const floatingStarsLeft = keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  to {
    transform: translate(0, -0px);
  }
`

export const floatingStarsRight = keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  to {
    transform: translate(0, -0px);
  }
`

// const TicketContainer = styled(Flex)``

// const PrizeTotalBalance = styled(Balance)`
//   background: ${({ theme }) => theme.colors.gradientGold};
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// `

// const StyledBuyTicketButton = styled(BuyTicketsButton)<{ disabled: boolean }>`
//   color: #fff;
//   width: 150px;
//   height: 55px;
//   border-radius: 45px;
//   border: none;

//   // background: rgb(34,193,195);
//   // background: linear-gradient(to top, #eb7dff, #dab1ff);
//   font-weight: initial;
//   color: white;

//   cursor: pointer;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-align-items: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -webkit-justify-content: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   &:hover {
//     opacity: 1 !important;
//   }
//   ${({ theme }) => theme.mediaQueries.xs} {
//     // width: 240px;
//   }
// `

// const ButtonWrapper = styled.div`
//   z-index: 1;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `

// const Banner = styled.div`
//   position: relative;
// `
// const LeftDiv = styled.div`
//   position: relative;
// `

const Hero = () => {
  const {
    currentRound: { amountCollectedInCake },
  } = useLottery()

  const cakePriceBusd = usePriceCakeUSD()
  const prizeInBusd = amountCollectedInCake.times(cakePriceBusd)
  const prizeTotal = getBalanceNumber(prizeInBusd)
  // const ticketBuyIsDisabled = status !== LotteryStatus.OPEN || isTransitioning

  // const getHeroHeading = () => {
  //   if (status === LotteryStatus.OPEN) {
  //     return (
  //       <>
  //         {prizeInBusd.isNaN() ? (
  //           <Skeleton my="7px" height={60} width={190} />
  //         ) : (
  //           <PrizeTotalBalance fontSize="64px" unit=" HFG" value={prizeTotal} mb="8px" decimals={0} />
  //         )}
  //         <Heading mb="32px" scale="lg" color="#ffffff">
  //           {t('in prizes!')}
  //         </Heading>
  //       </>
  //     )
  //   }
  //   return (
  //     <Heading mb="24px" scale="xl" color="#ffffff" textAlign="center">
  //       {t('Tickets on sale soon')}
  //     </Heading>
  //   )
  // }
  // const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {

  return (
    <div className="ticketimage">
      <div className="imgticket">
        <img style={{ width: '' }} alt="ticket-buy" src="/buyticket.png" />
        <div className="imgticket2">
          <img style={{ width: '' }} alt="ticket" src="/ticketmob.png" />
        </div>

        <div className="tickettext">
          <p>HF lottery</p>
          <div className="hfgtext">{prizeTotal.toFixed(2)} HFG</div>
          <h2> in prizes!</h2>
          {/* <div className="ticketbtn">
            <a type="button" href='' className="Btn">
              buy ticket
            </a>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Hero
